.contact {
  background-color: #222;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-form {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70%;
  max-width: 70em;
}

.contact-form form {
  width: 100%;
  max-height: 100%;
  overflow-x: visible;
  overflow-y: auto;
}

.contact-form input, textarea {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  padding: 0.4em 1em;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  margin: 1em 0;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-size: 1em;
}

.contact-form textarea {
  resize: none;
}

.contact-form label {
  font-size: 1.5em;
  font-weight: 600;
}

.contact-form button {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-size: 1.5em;
  font-weight: 600;
  background-color: white;
  border: none;
  color: #222;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  padding: 1em;
  transition: all 0.1s linear;
}

.contact-form button:hover {
  background-color: #555;
  color: #ddd;
  cursor: pointer;
  transition: all 0.1s linear;
}
