.header {
  display: flex;
  height: 10vh;
  align-items: center;
  margin-left: 3em;
}

.header-component {
  margin: 0 1em;
}

.logo {
  width: 10em;
}

.logo img {
  height: auto;
  width: 100%;
}

.name {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: center;
}

.name h1 {
  font-size: 2em;
}

.name h2 {
  font-size: 1.5em;
  color: grey;
}

@media (max-width: 1000px) {
  .logo {
    width: 6em;
  }

  .header {
    margin-left: 1.3em;
  }

  .name h1 {
    font-size: 1.5em;
  }

  .name h2 {
    font-size: 1.2em;
  }
}