.app {
  max-width: 1600px;
  margin: 0 auto;
  overflow-y: hidden;
}

footer {
  height: 5vh;
  font-size: 1.2em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #888;
}

footer p {
  margin: 0.15em 0;
}

footer a {
  text-decoration: underline;
}

@media (max-height: 675px) {
  footer {
    font-size: 1em;
  }
}

@media (max-height: 500px) {
  footer p {
    margin: 0;
    font-size: 0.85em;
  }
}