@font-face {
  font-family: ivymode;
  src: url(assets/fonts/ivymode/IvyMode-Regular.ttf);
}

.menu {
  display: flex;
  font-family: ivymode, sans-serif;
  font-size: 6em;
  height: 80vh;
  justify-content: space-between;
}

.menu-component {
  background-size: cover;
  border-radius: 1em;
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
  width: 25rem;
  max-width: 100%;
}

.photo-menu {
  background-image: url(./assets/img/photo-bg.webp);
  background-position: center;
}

.video-menu {
  background-image: url(./assets/img/videos-bg.webp);
  background-position: center;
}

.contact-menu {
  background-image: url(./assets/img/contact-bg.webp);
  background-position: center;
}

.menu-component h3 {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  width: max-content;
}

.menu-component h3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
  margin: 0 auto;
}

.menu-component:hover h3::before {
  visibility: visible;
  transform: scaleX(1);
}

.menu-component:hover {
  filter: brightness(130%);
  transition: all 0.3s ease-in-out;
}

@media (max-width: 1350px) {
  .menu {
    flex-direction: column;
  }
  
  .menu-component {
    background-size: cover;
    border-radius: 1em;
    -webkit-border-radius: 1em;
    -moz-border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(80vh/3 - 2rem);
    text-align: center;
    width: 100%;
    max-width: 100%;
  }

  .menu-component {
    margin-bottom: 1rem;
  }
}

@media (max-width: 750px) {
  .menu {
    font-size: 4em;
  }
}