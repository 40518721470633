@font-face {
	font-family: neue-haas-grotesk;
	src: url(assets/fonts/neue-haas-grotesk/NHaasGroteskTXPro-75Bd.ttf);
	font-weight: 800;
}

.videos {
  background-color: #222;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  height: 80vh;
  max-height: 900px;
  display: grid;
  grid-template-columns: 65% 35%;
  grid-template-rows: 15% 85%;
  width: 100%;
}

.videos h2 {
  font-size: 3.5em;
  font-weight: 800;
  margin-right: 1em;
}

.videos-title {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  align-items: center;
  padding-left: 10%;
  padding-top: 1.5%;
  width: 100%;
  height: 100%;
}

.video-responsive { 
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  max-width: 1920px;
  margin: auto;
  overflow: hidden;
  height: 100%;
  width: 100%;
  grid-column: 1;
  grid-row: 2;
}

.video-responsive div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 56.25%;
}

.video-responsive iframe {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  width: 90%;
  height: 90%;
}

.error-video-msg {
  grid-column: 1;
  grid-row: 2;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.videos-list {
  background-color: #181818;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  grid-column: 2;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  width: 90%;
  height: 90%;
  margin: auto;
}

.video-item {
  border-bottom: gray solid 1px;
  display: flex;
  width: 100%;
  padding: 0.5em 0.5em;
  cursor: pointer;
}

.video-item:hover:not(.selected) {
  background-color: #252525;
}

.selected {
  background-color: #2C2C2C;
}

.video-thumbnail {
  width: 25%;

}

.video-thumbnail img {
  width: 100%;
  height: 100%;
}

.video-descriptor-wrapper {
  width: 75%;
  display: flex;
}

.video-descriptor {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 2em;
  width: calc(100% - 4em);
}

.video-descriptor h6 {
  font-size: 1.3em;
  font-weight: 800;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-clamp: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.video-descriptor .description {
  color: grey;
  font-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.video-descriptor .date {
  color: #666;
  font-size: 1em;
}

@media (orientation: portrait) {
  .videos {
    grid-template-columns: 100%;
    grid-template-rows: 7.5% 42.5% 7.5% 42.5%;
  }

  .videos-list {
    grid-column: 1;
    grid-row: 3 / 5;
    font-size: 1.3em;
  }

  .videos h2 {
    font-size: 3em;
  }
}

@media (max-width: 800px) {
  .ytsubscribe-wrapper {
    display: none;
  }
}

@media (max-width: 1000px) {
  .videos h2 {
    font-size: 2.3em;
  }

  .video-descriptor h6 {
    font-size: 1em;
  }
}